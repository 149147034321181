<template>
  <div>
    <Table :table="table" @fetch-data="fetchData"  @search-data="initialize" 
      @uprole="upRole" @addNew="addNew"  />
    <v-dialog v-model="dialog" max-width="600px" ref="createNew">
      <v-card>
        <v-card-title>
          <span class="text-h5">Thêm Admin</span>
        </v-card-title>
        <v-card-text>
          <div>
            <v-container>
              <v-row>
                <v-col cols="12" sm="6">
                  <v-autocomplete 
                  v-model="user_add" 
                  :search-input.sync="search" 
                  label="Người đầu tư"
                    :items=user></v-autocomplete>

                </v-col>
              </v-row>
            </v-container>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="Add()">Thêm</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import Table from "@/components/core/TableAdmin"
import network from '@/core/network'
import { debounce } from 'lodash';

export default {
  components: {
    Table
  },
  data() {
    return {
      dialog: false,
      search: '',
      debouncedSearch: null,
      user_add: {},
      user: {},
      table: {
        search: '',
        page: 1,
        itemsPerPage: 10,
        totalItems: 0,
        desserts: [],
        formData: {
          name: '',
          role: ''
        },
        headers: [
          {
            text: 'Tên',
            value: 'name',
            sortable: false
          },
          {
            text: 'Role',
            value: 'role',
            sortable: false
          },
          {
            text: 'Mã mời',
            value: 'invite_code',
            sortable: false
          },
          {
            text: 'Lưu',
            value: 'created_at',
            sortable: false
          },

          { text: 'Thao tác', value: 'actions', sortable: false, width: "100px" },
        ],
      }
    }
  },
  watch: {
    search(val) {
      this.fetchListUser(val)
    },
  },
  created() {
    // Khởi tạo hàm debouncedSearch để sử dụng debounce
    this.debouncedSearch = debounce(this.searchUser, 500); // 500ms là khoảng thời gian debounce
  },
  mounted() {
    this.initialize();
    this.searchUser();
  },
  methods: {
    fetchData(page) {
      this.page = page;
      this.initialize()
    },

    initialize(searchData) {
      this.table.search = searchData
      network.get('/users/list-admin', {
        name: searchData ?? '',
        page: this.table.page,
        limit: 10
      }).then(res => {
        this.table.desserts = res.data.docs
        this.table.totalItems = res.data.totalDocs
      })
    },

    searchUser() {
      network.get('/users', {
        name: this.search,
        page: 0,
        limit: 10
      }).then(res => {
        this.user = res.data.docs.map(item => ({ text: item.name, value: item._id }));
      })
    },

    fetchListUser(val) {
      this.search = val;
      this.debouncedSearch();
    },

    addNew() {
      this.dialog = true;
    },

    upRole(data) {
      network.post('/users/uprole', data).then(res => {
        this.$toast.open({
          message: res.data.message,
          type: 'success',
          position: 'top'
        });
        this.initialize();
      }).catch(err => {
        this.$toast.open({
          message: err.response.data.message,
          type: 'error',
          position: 'top'
        });
      });
    },

    deleteUser(id) {
      network.post('/users/deleteUser',
        { id: id }
      ).then(res => {
        console.log(res);
        if (res.status == 200) {
          this.$toast.open({
            message: res.data.message,
            type: 'success',
            position: 'top'
          });
          this.initialize();
        } else {
          this.$toast.open({
            message: res.data.message,
            type: 'error',
            position: 'top'
          });
        }
      }).catch(err => {
        this.$toast.open({
          message: err.response.data.message,
          type: 'error',
          position: 'top'
        });
      });
    },

    Add() {
      network.post('/users/uprole', {
        id: this.user_add,
        role: 'ADMIN'
      }).then(res => {
        this.$toast.open({
          message: res.data.message,
          type: 'success',
          position: 'top'
        });
        this.initialize();
        this.dialog = false;
        this.user_add = {};
      }).catch(err => {
        this.$toast.open({
          message: err.response.data.message,
          type: 'error',
          position: 'top'
        });
      });
    }
  }

}
</script>

<style></style>